import { useContext } from 'react'
import MadMintThemeContext from '../../contexts/MadMint'
import { signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

export default function Header({ page }: any) {
  const { theme } = useContext(MadMintThemeContext)
  const { data: session } = useSession()
  const router = useRouter()

  const handleSignOut = async () => {
    signOut({ callbackUrl: `${window.location.origin}/login` })
  }

  return (
    <div className="flex header justify-between">
      <div
        className="logo-wrapper"
        onClick={() => !session?.user.id && router.replace('/')}
      >
        <img
          src="/images/legendsofrock/logo.png"
          alt="logo"
          className="header-logo"
        />
      </div>
      {session && (
        <div className="relative">
          <a
            className="text-white capitalize logout-text cursor-pointer"
            onClick={handleSignOut}
          >
            Logout
          </a>
        </div>
      )}
      <style jsx>{`
        .header {
          padding: 24px 80px;
          margin-left: ${page === "landing" ? "155px" : "0px"};
        }
        @media screen and (max-width: 1920px) {
          .header {
            margin-left: 0px;
          }
        }
        .nav-menu li {
          padding-right: 50px;
        }
        .nav-menu {
          color: ${theme.colors.bgPrimary};
        }
        .mobile-nav-menu {
          display: none;
        }
        .side-menu {
          height: 100vh;
          position: fixed;
          z-index: 120;
          left: 0px;
          top: 0px;
        }
        .side-menu {
          background-color: ${theme.colors.colorPrimary};
          color: ${theme.colors.bgSecondary};
        }
        .side-nav-menu {
          padding: 30px;
        }
        .side-nav-menu li {
          padding-top: 20px;
        }
        .logout-text {
          font-size: 32px;
          line-height: 38px;
          font-family: 'tt trailers';
          font-style: normal;
          font-weight: 800;
        }
        @media screen and (max-width: 767px) {
          .nav-menu {
            display: none;
          }
          .mobile-nav-menu {
            display: block;
          }
          .header {
            padding: 24px;
          }
          .header-logo {
            width: 100px;
          }
        }
      `}</style>
    </div>
  )
}
