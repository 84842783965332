import React, { ReactNode } from 'react'
import clsx from 'clsx'
import Header from './Header'
import Footer from './Footer'
import Copyright from './Copyright'
import useDetectWindowMode, {
  WINDOW_MODE,
} from '../../hooks/useDetectWindowMode'

type Props = {
  children: ReactNode
  className?: string
  page: string
}

const DP_BG: any = {
  login: 'login',
  landing: 'landing',
  project: 'project',
}

const Layout: React.FC<Props> = ({ children, className, page }) => {
  const windowMode = useDetectWindowMode()
  const isMobile = [
    WINDOW_MODE['XSM'],
    WINDOW_MODE['SM'],
    WINDOW_MODE['MD'],
  ].includes(windowMode)

  return (
    <div className="layout">
      <div className={clsx('login-background', className)}>
        <Header page={page}/>
        {children}
        <Footer page={page}/>
      </div>
      {/* <Copyright /> */}
      <style jsx>{`
        .layout {
          max-width: 1920px;
          margin: 0 auto;
          min-width: 375px;
          position: relative;
          height: 100vh;
        }
        .login-background {
          height: 100vh;
          display: flex;
          flex-direction: column;
        }
      `}</style>
      <style jsx global>{`
        body {
          background-image: ${isMobile
            ? `url(/images/legendsofrock/mobile-${DP_BG[page]}.png)`
            : `url(/images/legendsofrock/${DP_BG[page]}.png)`};
          background-size: ${DP_BG[page] === "project" ? "auto" : "100% 100%"};
        }
      `}</style>
    </div>
  )
}

export default Layout
