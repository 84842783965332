import { useContext } from 'react'
import MadMintThemeContext from '../../contexts/MadMint'

export default function Copyright() {
  const { theme } = useContext(MadMintThemeContext)
  return (
    <div className="flex copyright-section justify-between flex-col items-start lg:hidden">
      <ul className="flex copyright-menu flex-col items-start lg:flex-row">
        <li>
          <a href="/terms.pdf" target="_blank" className="flex">
            Terms & Conditions
          </a>
        </li>
        <li>
          <a href="https://madworld.io/aml" target="_blank" className="flex">
            AML/KYC Policy
          </a>
        </li>
        <li>
          <a
            href="https://madworld.io/privacy"
            target="_blank"
            className="flex"
          >
            Privacy
          </a>
        </li>
        <li>
          <a
            href="https://madworld.io/disclaimer"
            target="_blank"
            className="flex"
          >
            Disclaimer
          </a>
        </li>
      </ul>
      <style jsx>{`
        .copyright-section {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${theme.colors.bgPrimary};
          padding: 24px 32px;
        }
        .copyright-menu li {
          padding-right: 30px;
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: ${theme.colors.gray};
        }
        @media screen and (max-width: 1023px) {
          .copyright-section {
            font-size: 12px;
            color: #e5e5e5;
          }
          .copyright-menu li {
            padding-top: 15px;
          }
        }
      `}</style>
    </div>
  )
}
