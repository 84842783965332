import { SessionProvider } from 'next-auth/react'
import { Session } from 'next-auth'
import { FC, useState } from 'react'
import fetcher from 'fetcher'
import { SWRConfig } from 'swr'
import { CNOptions, NotificationContext } from 'mad-mint/contexts/Notification'
import '../styles/global.css'
import Notifier from 'mad-mint/components/Notifier'
import AdminAuthGuard from 'mad-mint/components/Authentication/admin'
import CreatorAuthGuard from 'mad-mint/components/Authentication/creator'
import AuthGuard from 'mad-mint/components/Authentication/registered'
import Head from 'next/head'
import Script from 'next/script'

type AppProps = {
  Component: FC & {
    requireAuth?: boolean
    requireAdmin?: boolean
    requireCreator?: boolean
  }
  pageProps: {
    session: Session | null
  }
}

const swrConfigOpts = {
  fetcher,
}

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const notificationState = useState<CNOptions>(null)

  return (
    <SessionProvider session={session}>
      <Head>
        <link rel="shortcut icon" href="/images/legendsofrock/favicon.ico" />
        <link
          rel="preload"
          href="/fonts/Poppins-Bold.ttf"
          as="font"
          crossOrigin=""
        />
      </Head>
      <Script type="text/javascript" src="https://cdn.weglot.com/weglot.min.js" strategy="beforeInteractive"></Script>
      <Script
        id="weglot-init" strategy="afterInteractive"
      >
            {`
              Weglot.initialize({
                  api_key: '${process.env.NEXT_PUBLIC_WEGLOT_API_KEY}'
              });
            `}
      </Script>
      <Script id="google-analytics" strategy="beforeInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
      `}
      </Script>
      <SWRConfig value={swrConfigOpts}>
        <NotificationContext.Provider value={notificationState}>
          {Component.requireAdmin ? (
            <AdminAuthGuard>
              <Component {...pageProps} />
            </AdminAuthGuard>
          ) : Component.requireCreator ? (
            <CreatorAuthGuard>
              <Component {...pageProps} />
            </CreatorAuthGuard>
          ) : Component.requireAuth ? (
            <AuthGuard>
              <Component {...pageProps} />
            </AuthGuard>
          ) : (
            <Component {...pageProps} />
          )}

          <Notifier />
        </NotificationContext.Provider>
      </SWRConfig>
    </SessionProvider>
  )
}
