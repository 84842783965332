import { useContext, useEffect, useState } from 'react'
import Icon from '../../components/Icon'
import MadMintThemeContext from '../../contexts/MadMint'
import useDetectWindowMode, {
  WINDOW_MODE,
} from '../../hooks/useDetectWindowMode'
import Copyright from './Copyright'

export default function Footer({ page }: any) {
  const { theme } = useContext(MadMintThemeContext)
  const windowMode = useDetectWindowMode()

  const isMobile = [
    WINDOW_MODE['XSM'],
    WINDOW_MODE['SM'],
    WINDOW_MODE['MD'],
  ].includes(windowMode)

  return (
    <div className="flex justify-center footer-section">
      <div className="flex w-full xl:w-4/5 justify-between flex-col items-start lg:flex-row footer-social-links-wrapper">
        <div>
          <div className="flex items-start footer-social-section">
            <h6 className="white-text connect-text pt-7 md:pt-9">
              Connect with us
            </h6>
            <ul className="socials-menu flex justify-between pt-7 md:pt-9">
              <li className="flex">
                <a
                  href="https://twitter.com/legendsofrockio"
                  target="_blank"
                  className="flex"
                >
                  <Icon
                    icon="twitter"
                    size={isMobile ? 22 : 17}
                    color={theme.colors.bgPrimary}
                  />
                </a>
              </li>
              <li className="flex">
                <a
                  href="http://discord.gg/madworldnft"
                  target="_blank"
                  className="flex"
                >
                  <Icon
                    icon="discord2"
                    size={isMobile ? 22 : 17}
                    color={theme.colors.bgPrimary}
                  />
                </a>
              </li>
              <li className="flex">
                <a
                  href="https://www.instagram.com/legendsofrockio"
                  target="_blank"
                  className="flex"
                >
                  <Icon
                    icon="instagram"
                    size={isMobile ? 22 : 17}
                    color={theme.colors.bgPrimary}
                  />
                </a>
              </li>
              <li className="flex">
                <a
                  href="https://www.tiktok.com/@legendsofrockio"
                  target="_blank"
                  className="flex"
                >
                  <Icon
                    icon="tiktok"
                    size={isMobile ? 22 : 17}
                    color={theme.colors.bgPrimary}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <ul className="white-text text-small footer-menu">
            <li>
              <a
                href="/terms.pdf"
                target="_blank"
                className="flex"
              >
                Terms & Conditions
              </a>
            </li>
            <li>
              <a
                href="https://madworld.io/aml"
                target="_blank"
                className="flex"
              >
                AML/KYC Policy
              </a>
            </li>
            <li>
              <a
                href="https://madworld.io/privacy"
                target="_blank"
                className="flex"
              >
                Privacy
              </a>
            </li>
            <li>
              <a
                href="https://madworld.io/disclaimer"
                target="_blank"
                className="flex"
              >
                Disclaimer
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Copyright />
      <style jsx>{`
        .white-text {
          color: ${theme.colors.bgPrimary};
        }
        .text-small {
          font-size: 16px;
          line-height: 20px;
          font-style: normal;
          font-weight: 500;
          font-family: 'Poppins-Regular';
        }
        .connect-text {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          color: ${theme.colors.white};
        }
        .footer-menu {
          display: flex;
          justify-content: flex-end;
          padding-top: 2.25rem;
          gap: 32px;
        }
        .footer-menu li {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
          z-index: 10;
        }
        .footer-section {
          margin-top: auto;
          padding: 0px 30px 36px 30px;
          width: 1202px;
          justify-content: space-between;
          margin-left: auto;
          margin-right: auto;
        }
        .footer-social-links-wrapper {
          width: 100%;
        }
        .socials-menu {
          gap: 26px;
        }
        .socials-menu li {
          cursor: pointer;
        }
        .footer-social-section {
          gap: 26px;
        }
        @media screen and (max-width: 1920px) {
          .footer-section {
            width: unset;
            margin-left: 155px;
            margin-right: 155px;
          }
        }
        @media screen and (max-width: 1279px) {
          .footer-section {
            margin-left: 80px;
            margin-right: 80px;
          }
        }
        @media screen and (max-width: 1023px) {
          .footer-menu {
            display: none;
          }
          .footer-section {
            margin-left: 0px;
            margin-right: 0px;
          }

          .footer-menu li {
            padding-right: 0;
          }
          .footer-social-section {
            gap: 0;
            flex-direction: column;
            align-items: flex-start;
          }
        }
        @media screen and (max-width: 1023px) {
          .footer-section {
            display: flex;
            flex-direction: column;
            padding: 140px 0px 0px 0px;
          }
          .footer-social-links-wrapper {
            padding: 0px 30px;
          }
        }
      `}</style>
    </div>
  )
}
